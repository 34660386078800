<template>
    <div class="reg-box">
        <div class="reg-title">
            <div class="reg-ch-title">小程序政府备案</div>
            <div class="reg-en-title">Mini Program Government Filing</div>
        </div>
        <div>
            <div class="ast-reg-steps">
                <div class="ast-reg-step active">1</div>
                <div class="ast-reg-step active">2</div>
                <div class="ast-reg-step active">3</div>
                <div class="ast-reg-step active">4</div>
                <div class="ast-reg-step">5</div>
            </div>
            <div class="cm-item mb-8">
                <span class="text-danger"
                      style="font-size: 16px;">根据国家互联网监管政策，小程序必须通过备案后方可正常发布使用</span>
            </div>

            <template v-if=" step === 1">

                <div class="cm-form">
                    <div class="cm-item">
                        <div>申请小程序备案之前，请先完成小程序负责人人脸核身。</div>
                        <div>
                            发起备案小程序管理员人脸核身，调用该接口时会向当前小程序的管理员发送一条备案人脸核身通知，管理员点击该通知即可进行人脸核身。
                        </div>
                        <div class="mb-4">
                            待管理员完成核身之后，即可开始申请备案。
                        </div>
                    </div>
                    <div class="cm-item">
                        <div class="cm-title">注意事项：</div>
                        <div>
                            <div class="mb-2">1. 人脸核身视频会用于生成核验照片，<span class="text-danger">背景需白色或接近浅色，核验人不能裸露上半身，不能戴帽子、耳机、口罩等遮挡面部的物品</span>。
                            </div>
                            <div class="mb-2">2. 人脸核身通知有效期为 24
                                小时，如果在通知有效期内核身未能通过，可以再次点击通知进行重试，无需重新发起，过期后需重新发起。
                            </div>
                            <div class="mb-2">3. 人脸核身有效期为 3
                                天，为避免申请备案时过期，请确保其余所有备案材料已准备好，以及备案前置条件已完成，再发起人脸核身。
                            </div>
                        </div>
                    </div>
                    <div class="cm-item">
                        <template v-if="!icpFaceInfo">
                            <a-button :disabled="faceBtnCountDown > 0 " @click="createIcpVerifyTask" type="primary">
                                <span>发起人脸核身</span>
                                <span v-if="faceBtnCountDown > 0">（阅读计时：{{ faceBtnCountDown }}）</span>
                            </a-button>
                        </template>
                        <template v-else-if="icpFaceInfo['face_status'] === 2 && icpFaceInfo['is_finish'] ">
                            <a-alert class="mb-4" type="error" message="人脸核身失败"/>
                            <a-button @click="createIcpVerifyTask" type="primary">
                                <span>重新发起人脸核身</span>
                            </a-button>
                        </template>
                        <template v-else>
                            <a-button type="primary" icon="loading">
                                人脸核身状态：{{ icpTaskStatusInfo[`a${icpFaceInfo['face_status']}`] }}
                            </a-button>
                        </template>
                    </div>
                </div>

            </template>
            <template v-else-if="step === 2">
                <div class="cm-form">
                    
                    <div class="cm-item">
                        <div class="cmf-title">小程序名称</div>
                        <div>
                            <div class="mb-4">
                                <b>{{ app.nickname }}</b>
                                <wechat-edit-name :app="app" />
                            </div>
                            <div class="tips">
                                <div>小程序修改名称次数有限。</div>
                                <div>特俗情况下,备案没有通过需要改名时,在此处修改。</div>
                            </div>
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">注册主体企业或个体工商户营业执照</div>
                        <div class="flex flex-left-center">
                            <upload-image
                                    style="width: 200px"
                                    :imageUrl="business_license_img"
                                    :done="busLicUploadDone"
                                    :action="uploadBusLicUrl"
                                    class="pay-apply-upload"
                                    @del=" business_license_img = '' "
                            />
                            <div class="tips ml-8">
                                <div>请上传营业执照，需年检章齐全，当年注册除外。</div>
                                <div>格式要求：原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg
                                    .png格式照片，大小不超过10M。
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">营业执照所属区域</div>
                        <a-cascader
                                v-model="banArea"
                                class="full-with at-select"
                                :fieldNames="areaFieldNames"
                                :options="areas"
                                placeholder="请选择备案省市区"
                                @change="areaChanged"/>
                    </div>

                    <div class="cm-item" v-if="showCommitmentLetterId">
                        <div>备案的非个人主体小程序需下载<a
                                @click=" showGuangDongLetterModal = true ">互联网信息服务承诺书（单位）</a>，
                        </div>
                        <div>并按照说明填写、签字盖章后上传提交审核。</div>
                    </div>
                    <div class="cm-item" v-if="showCommitmentLetterId">
                        <div class="cm-title">互联网信息服务承诺书</div>
                        <upload-image v-model="params.icp_materials.commitment_letter_img"/>
                        <div class="cm-tips">格式要求：互联网信息服务承诺书照片、扫描件或者加盖公章的复印件。 <br>
                            <span> 支持.jpg .jpeg .png格式照片，大小不超过2M。</span>
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">通讯地址</div>
                        <at-input :unit="areaText" v-model="params.icp_subject.base_info.address"
                                  class="full-with iba-address"/>
                        <div class="cm-tips">通讯地址，必须属于备案省市区，<span
                                class="text-danger">地址开头的省市区不用填入</span>
                            ，例如：通信地址为“北京市朝阳区高碑店路181号1栋12345室”时，只需要填写
                            "高碑店路181号1栋12345室" 即可
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">营业执照地址</div>
                        <at-input v-model="params.icp_subject.organize_info.certificate_address"
                                  class="full-with iba-address"/>
                    </div>

                    <div class="cm-tw">
                        <div class="cm-item">
                            <div class="cmf-title">法人{{ masterName }}联系方式</div>
                            <at-input v-model="params.icp_subject.principal_info.mobile" class="full-with"/>
                        </div>

                        <div class="cm-item">
                            <div class="cmf-title">法人{{ masterName }}应急联系方式</div>
                            <at-input v-model="params.icp_subject.principal_info.emergency_contact" class="full-with"/>
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">邮箱（接收小程序相关信息邮件）</div>
                        <at-input v-model="params.icp_subject.principal_info.email" class="full-with"/>
                    </div>

                    <div class="cm-tw">
                        <div class="cm-item">
                            <div class="cm-title">法人{{ masterName }}身份证正面</div>
                            <upload-image v-model="params.id_card_a_info.img_url" :action="uploadIdCardAUrl"
                                          :done="idCardADone"/>
                        </div>
                        <div class="cm-item">
                            <div class="cm-title">法人{{ masterName }}身份证反面</div>
                            <upload-image v-model="params.id_card_b_info.img_url" :action="uploadIdCardBUrl"
                                          :done="idCardBDone"/>
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cm-title">法人{{ masterName }}身份证号码</div>
                        <at-input v-model="params.icp_subject.principal_info.certificate_number" class="full-with"/>
                    </div>

                    <div class="cm-tw">
                        <div class="cm-item">
                            <div class="cm-title">身份证有效期起始日期</div>
                            <at-input v-model="params.icp_subject.principal_info.certificate_validity_date_start"
                                      class="full-with"/>
                            <div class="cm-tips">示例值："20230815"</div>
                        </div>
                        <div class="cm-item">
                            <div class="cm-title">身份证有效期截止日期</div>
                            <at-input v-model="params.icp_subject.principal_info.certificate_validity_date_end"
                                      class="full-with"/>
                            <div class="cm-tips">如证件长期有效，请填写 "长期"，示例值："20330815"</div>
                        </div>
                    </div>

                    <div class="cm-item">
                        <div class="cm-title">
                            经营内容说明
                            <span style="color: red">(为快速过审，法人接审核电话请按以下业务范围进行说明！！！)</span>
                        </div>
                        <a-textarea rows="8" v-model="params.icp_applets.base_info.comment"
                                    :maxLength="120"
                                    class="at-textarea mb-4"></a-textarea>
                        <div class="cm-tips">请具体描述小程序实际经营内容、主要服务内容，该信息为主管部门审核重要依据，备注内容字数限制20-200字，请认真填写。（特殊备注要求请查看<a
                                @click="showTipsModal = true">注意事项</a>）
                        </div>
                    </div>


                    <div>
                        <button @click="confirmApply" class="btn" style="margin-right: 100px">备案提交</button>

                        <a-button class="btn" type="primary" @click="redoFaceTask">重新发起人身核验</a-button>
                    </div>

                </div>
            </template>
            <template v-else>
                <a-result v-if="icpResult" style="width: 380px;padding: 0;margin-bottom: 32px">
                    <template #title>
                        {{ icpStatusInfo[`a${icpResult['status']}`] }}
                    </template>
                    <template #icon>
                        <template v-if="[2,4].indexOf(icpResult.status) >= 0 ">
                            <a-icon type="smile" theme="twoTone"/>
                        </template>
                        <template v-else>
                            <a-icon style="color:red" type="close-circle"/>
                        </template>
                    </template>
                </a-result>

                <div style="width: 500px" v-if="icpResult">

                    <div v-if="[2,4].indexOf(icpResult.status) < 0">

                        <template v-for="(item,ikk) in icpResult.audit_data">
                            <div class="mb-4" :key="ikk">
                                <div class="mb-2">{{ ikk + 1 }}. {{ item['key_name'] }}{{ item['error'] }}</div>
                                <div v-html="item.suggest"></div>
                            </div>
                        </template>

                        <div style="margin-bottom: 26px;"></div>

                        <a-button class="mr-4" @click="redo" type="primary">重新填写</a-button>

                    </div>

                </div>

            </template>
        </div>
        <div>

        </div>

        <a-modal v-model="showTipsModal" title="小程序备注注意事项" :footer="null">
            <div class="cm-item">
                <div class="mb-4">
                    1.天津市个人主体备案小程序请按以下格式补充备注：应急联系电话手机号使用人为XXX，与小程序主办者关系为XX
                    （注：应急联系方式必须为小程序主办者的直系亲属）；天津市非个人主体备案小程序请按以下格式补充备注：应急联系电话手机号使用人为XXX公司员工XXX。
                </div>
                <div class="mb-4">
                    2.福建省个人主体备案小程序请按以下格式补充备注：请具体描述小程序实际经营内容、主要服务内容，该信息为主管部门审核重要依据，备注内容字数限制20-200字，请认真填写。且不能出现“**公司官网，**单位旗下产品”。
                </div>
                <div>
                    3.广东省备案小程序如果是非纯中文小程序昵称，要求在小程序备注位置注明中文名称以及小程序主要服务内容，并且在备注文案文末加上“承诺遵守中华人民共和国法律法规。”，备注内容字数限制20-200字，请认真填写。
                </div>
            </div>
        </a-modal>

        <a-modal v-model="showErrModal" title="备案信息提交失败" :footer="null">
            <template v-for="(item,ik) in errrHints">
                <div class="mb-4" :key="ik">
                    <div v-if="item['errcode'] === 86321 ">
                        <div class="mb-8">{{ ik + 1 }}. 人脸核身政府端验证失败，请重新进行人脸核身</div>
                        <div class="cm-item">
                            <div class="cm-title">注意：</div>
                            <div class="mb-8">
                                <div class="mb-2">
                                    <span>人脸核身视频会用于生成核验照片，</span>
                                    <span class="text-danger">背景需白色或接近浅色，核验人不能裸露上半身，不能戴帽子、耳机、口罩等遮挡面部的物品</span>。
                                </div>
                            </div>
                        </div>

                        <div class="cm-item">
                            <div class="cm-title mv-4">人脸核身常见失败及解决方案 ：</div>
                            <div class="mb-8">
                                <div class="mb-2">
                                    <div class="mb-4">
                                        1、人脸视频背景非纯白色,请尝试在无杂物的白色背景下重新进行人脸核身
                                    </div>
                                    <div class="mb-4">2、人脸视频人脸占比太大,请尽量尝试远离镜头重新进行人脸核身</div>
                                    <div class="mb-4">3、人脸视频闭眼占比过多,请尽量尝试睁开眼睛重新进行人脸核身</div>
                                    <div class="mb-4">4、人脸视频光线过曝,请尝试去暗一点的地方重新进行人脸核身</div>
                                    <div class="mb-4">5、人脸视频光线过暗,请尝试去亮一点的地方重新进行人脸核身</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>{{ ik + 1 }}. {{ item.errmsg }}</div>
                </div>
            </template>
        </a-modal>

        <a-modal width="800px" v-model="showGuangDongLetterModal" title="需填写上传承诺书"
                 :footer="null">
            <div class="letter-container">
                <div>
                    <img src="https://res.wx.qq.com/op_res/xQ89nC9YgSWYRPb8wAk2jcJKGScKxIvha8AG9J3QbhU_FZG9xmB6mWQZ8Bg9wJLLV3xlWNz1rry7SC_GOWCGsQ"
                         alt="">
                </div>
                <div class="summary">
                    <div>
                        下载 <a
                            href="https://res.wx.qq.com/op_res/HoIJ34sposVj1qLmnNVe6oyeZux7LVTEXBMpLeOfgqUAZ_HLm1OHDgFCPaMFNDLhw-kEBDVxkd1_dd044yHUIQ">互联网信息服务承诺书（单位）</a>
                    </div>
                    <div>1. 填写深圳市腾讯计算机系统有限公司。</div>
                    <div>2. 填写广东省。</div>
                    <div>3. 单位法定代表人签名（需手写正楷签名，接受签名章，不接受连笔签）。</div>
                    <div>4.
                        加盖单位公章（不接受合同章、项目章等）。若个体工商户无公章，需要主体负责人手写日期+签名+盖手印+身份证号码。
                    </div>
                    <div>5. 填写日期应与提交备案日期一致，或小于2个月。</div>

                </div>
            </div>
        </a-modal>

    </div>
</template>

<script>
import api from "@/repo/api";
import AtInput from "@/components/AtInput.vue";
import UploadImage from "@/components/UploadImage.vue";
import WechatEditName from "@/components/WechatEditName.vue";

export default {
    name: "Auth",
    components: {WechatEditName, UploadImage, AtInput},
    computed: {
        uploadIdCardAUrl() {
            return api.colUrl('/ast-reg/ocr-id-card-a')
        },
        uploadIdCardBUrl() {
            return api.colUrl('/ast-reg/ocr-id-card-b')
        },
        uploadBusLicUrl() {
            return api.colUrl('/ast-reg/ocr-business-license')
        }
    },
    data() {
        return {
            step: 1,
            showGuangDongLetterModal: false,
            errrHints: [],
            showErrModal: false,
            faceBtnCountDown: 15,
            icpResult: null,
            icpFaceResult: null,
            icpFaceInfo: null,
            showTipsModal: false,
            timerFlag: null,
            areas: [],
            banArea: [],
            areaText: "",
            business_license_img: "",
            business_license_name: "",
            areaFieldNames: {
                label: 'name',
                value: 'code',
                children: 'sub_list'
            },
            icpTaskStatusInfo: {
                a0: "已推送法人，法人暂未开始核验",
                a1: "人脸核身进行中",
                a2: "人脸核身失败",
                a3: "人脸核身成功",
            },
            icpStatusInfo: {
                a2: "平台审核中",
                a3: "平台审核驳回",
                a4: "管局审核中",
                a5: "管局审核驳回",
                a1024: "未备案",
                a1026: "失败，小程序类目未填",
                a1027: "失败，小程序信息未填",
                a1028: "小程序未认证，请先完成认证",
                a1029: "小程序未认证，请先完成认证",
                a1030: "小程序未认证，请先完成认证",
                a1031: "小程序未认证，请先完成认证",
            },
            showCommitmentLetterId: 0,
            masterName: "",
            params: {
                app_id: null,
                icp_subject: { // 主体基本信息
                    base_info: {
                        province: undefined, // 省
                        city: undefined, //市
                        district: undefined, // 区
                        address: "", //地址
                    },
                    principal_info: {  // 主体负责人信息
                        mobile: "", // 	负责人联系方式
                        email: "", // 负责人电子邮件
                        emergency_contact: "", // 	负责人应急联系方式
                        certificate_number: "",
                        certificate_validity_date_start: "",
                        certificate_validity_date_end: "",
                    },
                    organize_info: {
                        certificate_address: "",
                    }
                },
                icp_applets: { // 微信小程序信息
                    base_info: {
                        comment: "本小程序为用户提供个人形象管理服务，在线为用户提供服饰搭配方案供用户参考，管理用户身高、体重等形象档案信息。", // 请具体描述小程序实际经营内容、主要服务内容，该信息为主管部门审核重要依据，备注内容字数限制20-200字，请认真填写。（特殊备注要求请查看注意事项）
                    },
                },
                icp_materials: {
                    commitment_letter_img: "",
                },
                id_card_a_info: {
                    img_url: null,
                }, // 身份证正面信息
                id_card_b_info: {
                    img_url: null,
                }, // 身份证反面信息
            }
        }
    },
    props: {
        companyInfo: Object,
        app: Object,
    },
    watch: {
        companyInfo: {
            handler() {
                this.loadCompanyInfo()
            },
            deep: true
        }
    },
    mounted() {
        this.loadCompanyInfo()
        this.fetchAreas()
        this.countDown()
    },
    destroyed() {
        if (this.timerFlag) {
            clearTimeout(this.timerFlag)
        }
    },
    methods: {
        busLicUploadDone(res) {

            if (res['enterprise_name'] !== this.business_license_name) {
                return this.$message.error('营业执照主体名称与申请小程序不一致');
            }


            this.$loading.show()

            api.post('/ast-reg/update-business-license-info', {
                business_license_info: res,
                id: this.companyInfo.id,
            }, data => {

                this.$loading.hide()

                if (data.code === 0) {

                    this.$message.success('上传成功，页面即将刷新.')

                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)

                } else {
                    this.$message.data(data)
                }

            });
        },
        countDown() {
            setTimeout(() => {
                this.faceBtnCountDown--;
                if (this.faceBtnCountDown > 0) {
                    this.countDown()
                }
            }, 1000)
        },
        createIcpVerifyTask() {

            this.$loading.show()

            api.post('/ast-reg/create-icp-verify-task', {app_id: this.app.id}, data => {

                this.$message.data(data)

                if (data.code === 0) {
                    this.queryIcpVerifyTask()
                }

            })
        },
        queryIcpVerifyTask() {

            api.post('/ast-reg/query-icp-verify-task', {app_id: this.app.id}, data => {

                this.$loading.hide()

                if (data.code !== 0) {
                    this.$message.data(data)
                }

                if (data.data.is_finish && data.data.face_status === 3) {

                    this.step = 2

                    this.icpFaceResult = 3

                    this.$message.success('人身核验完成。');

                    this.clearTimer()

                    return;
                }

                this.icpFaceInfo = data.data;

                if (data.data.face_status === 2) return;

                this.timerFlag = setTimeout(() => {

                    this.queryIcpVerifyTask()

                }, 5000)

            })
        },
        fetchAreas() {
            api.get('/ast-reg/areas', (data) => {
                this.areas = data.data;
                this.loadAreaInfo()
            })
        },
        redo() {
            this.clearTimer()
            this.step = 2
        },
        clearTimer() {
            if (this.timerFlag) clearTimeout(this.timerFlag)
        },
        areaChanged(e) {
            console.log(e);
            this.params.icp_subject.base_info.province = e[0];
            this.params.icp_subject.base_info.city = e[1];
            this.params.icp_subject.base_info.district = e[2];

            this.loadAreaInfo();
        },
        loadAreaInfo() {

            if (!this.areas.length || !this.banArea.length) return;

            this.areaText = this.matchAreaName(this.areas, 0);

            let whiteList = [
                440000, // 广东
                420000,
                // 110000, // 北京
                // 420000, // 湖北
                // 520000, // 贵州
                // 330000, // 浙江
            ];

            if (whiteList.indexOf(this.banArea[0]) >= 0) {

                this.showCommitmentLetterId = this.banArea[0]

                if (!this.params.icp_materials.commitment_letter_img) {
                    this.showGuangDongLetterModal = true
                }

            } else {

                this.showCommitmentLetterId = 0

            }
        },
        matchAreaName(areas, level) {

            let name = ''

            for (const areasKey in areas) {

                const item = areas[areasKey]

                if (item.code !== this.banArea[level]) continue;

                name = item.name

                if (item.sub_list) {
                    level++;
                    name += this.matchAreaName(item.sub_list, level)
                }

                break;
            }

            return name;
        },
        loadCompanyInfo() {

            this.params.app_id = this.app.id;

            if (!this.companyInfo) return;

            this.masterName = `(${this.companyInfo.business_license_info.legal_representative})`

            this.params.icp_subject.base_info.address = this.companyInfo.business_license_info.address

            this.business_license_img = this.companyInfo.business_license_info.img_url
            this.business_license_name = this.companyInfo.business_license_info.enterprise_name

            if (this.companyInfo.ban_info) {

                this.$deepMerge(this.params, this.companyInfo.ban_info)

                this.banArea = [
                    this.params.icp_subject.base_info.province,
                    this.params.icp_subject.base_info.city,
                    this.params.icp_subject.base_info.district
                ]

                this.loadAreaInfo()
            }

            if (this.companyInfo.auth_info && !this.params.icp_subject.principal_info.email) {
                this.params.icp_subject.principal_info.email = this.companyInfo.auth_info.contact_info.email
            }

            if (this.companyInfo.business_license_info && !this.params.icp_subject.organize_info.certificate_address) {
                this.params.icp_subject.organize_info.certificate_address = this.companyInfo.business_license_info.address
            }

            this.icpFaceResult = this.companyInfo.ast_app.info.icp_face_result

            if ((!this.icpFaceResult) && this.companyInfo.ast_app.info.icp_face_task_id) {
                this.queryIcpVerifyTask()
            }

            if (this.icpFaceResult === 3) this.step = 2;

            let icpResult = this.companyInfo.ast_app.info.icp_status

            if (icpResult === 6) {
                return this.$emit('success');
            }

            if (icpResult > 0 && icpResult < 10) {

                this.step = 3

                this.getBanStatus(true)
            }

            if (icpResult === 0) this.getBanStatus(false)
        },
        idCardADone(res) {
            this.params.id_card_a_info = res
            this.params.icp_subject.principal_info.certificate_number = res.id
        },
        idCardBDone(res) {
            this.params.id_card_b_info = res

            this.params.icp_subject.principal_info.certificate_validity_date_start = res.date_begin.replaceAll('-', '')
            this.params.icp_subject.principal_info.certificate_validity_date_end = res.date_end.replaceAll('-', '')
        },
        confirmApply() {

            this.$loading.show()

            api.post('/ast-reg/ban', this.params, (data) => {

                this.$loading.hide();

                if (data.code !== 0) {
                    this.$message.error(data.msg)
                    return
                }

                if (data.data && data.data.errcode === 86202) {

                    this.errrHints = data.data.hints
                    this.showErrModal = true

                    return;
                }

                this.step = 3;
                this.getBanStatus(true)

            });
        },
        redoFaceTask() {
            this.clearTimer()

            this.$loading.show()

            api.post('/ast-reg/redo-icp-face-task', {app_id: this.app.id}, data => {

                this.$loading.hide()

                if (data.code !== 0) return;

                this.showErrModal = false
                this.step = 1

                this.faceBtnCountDown = 15;
                this.countDown()
            })

        },
        getBanStatus(repeat) {

            api.get('/ast-reg/ban-status', {app_id: this.app.id}, data => {

                if (data.code !== 0) return this.$message.error(data.msg)


                this.icpResult = data.data

                if (this.icpResult.status <= 6 && this.icpResult.status > 0) {
                    this.step = 3;
                }

                if (this.icpResult.status === 6) {

                    this.$message.success('备案已完成');

                    this.step = 99

                    return this.$emit('success');
                }

                if ([3, 5].indexOf(this.icpResult.status) >= 0) return;

                if (repeat) this.timerFlag = setTimeout(() => {

                    this.getBanStatus(repeat)

                }, 5000)
            })

        }
    }
}
</script>

<style lang="less" scoped>

.reg-title {
    height: 64px;
}

.reg-selections {
    margin-top: 72px;

}

.icon-box {
    width: 80px;
    height: 80px;
    background: #F5F5F5 url("/assets/icons/xcx-icon-black.png") no-repeat center;
    background-size: 32px;
    margin-right: 16px;

    &.green {
        background: rgba(6, 222, 107, 0.2) url("/assets/icons/xcx-icon-green.png") no-repeat center;
        background-size: 32px;
    }
}

.reg-selection {
    border: 1px solid #EEEEEE;
    padding: 20px;
    width: 440px;
    margin-bottom: 32px;

    background-image: url("/assets/icons/ast-reg-right.png");
    background-repeat: no-repeat;
    background-size: 74px;
    background-position: 326px 79px;

    display: flex;


    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;

    cursor: pointer;
}

.selection-title {
    margin-top: 12px;
    margin-bottom: 12px;
}

.company-reg {
    margin-top: 72px;
    margin-left: 120px;
}

.company-reg-title {
    width: 177px;
    height: 64px;

    background-image: url("/assets/icons/compang-reg-title.png");
    background-size: 177px;
}

.ast-reg-steps {
    margin-bottom: 32px;
}

.summary-area {
    height: 120px;
}

.iba-address {
    /deep/ .unit {
        font-size: 14px;
    }
}

.letter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 100%;
    }

    > div {
        flex: 1;
    }

    .summary {
        padding-left: 20px;

        div {
            margin-bottom: 10px;
        }
    }
}
</style>